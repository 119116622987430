import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { RadiographyContourCommentsResponseType } from './types';
import {
    deleteRadiographyContourComments,
    getRadiographyContourCommentsById,
    getRadiographyContourCommentsList,
    updateRadiographyContourComments,
} from './actions';
import { IPagingSearch } from '../../../interfaces';

const { name } = options;

type RadiographyContourCommentsState = {
    radiographyContourCommentsList: RadiographyContourCommentsResponseType[];
    isLoading: boolean;
    error: string;
};

const initialState: RadiographyContourCommentsState = {
    radiographyContourCommentsList: [],
    isLoading: false,
    error: '',
};

export const RadiographyContourCommentsSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearRadiographyContourCommentsList(state) {
            state.radiographyContourCommentsList = [];
        },
    },
    extraReducers: {
        [getRadiographyContourCommentsList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getRadiographyContourCommentsList.fulfilled.type]: (
            state,
            {
                payload,
            }: PayloadAction<
                IPagingSearch<RadiographyContourCommentsResponseType>
            >
        ) => {
            state.isLoading = false;
            state.radiographyContourCommentsList = payload.results;
            state.error = '';
        },
        [getRadiographyContourCommentsList.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.radiographyContourCommentsList = [];
            state.error = payload;
        },
        [getRadiographyContourCommentsById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getRadiographyContourCommentsById.fulfilled.type]: (
            state,
            { payload }: PayloadAction<RadiographyContourCommentsResponseType>
        ) => {
            state.isLoading = false;
            state.error = '';
        },
        [getRadiographyContourCommentsById.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateRadiographyContourComments.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateRadiographyContourComments.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateRadiographyContourComments.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteRadiographyContourComments.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteRadiographyContourComments.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteRadiographyContourComments.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export default RadiographyContourCommentsSlice.reducer;
