import React, { useRef, useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getWorkerList } from 'models/dictionaries/dict-worker/actions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DictWorkerResponseType } from 'models/dictionaries/dict-worker/types';
import { selectDictWorkerState } from 'models/dictionaries/dict-worker/selectors';
import { WorkgroupDialog } from './workgroup-dialog';
import { WorkgroupDeletingDialog } from './workgroup-deleting-dialog';
import {
    DictWorkGroupResponseType,
    WorkgroupTableDataType,
} from 'models/dictionaries/dict-workgroup/types';
import {
    deleteDictWorkGroup,
    getDictWorkGroupList,
} from 'models/dictionaries/dict-workgroup/actions';
import { selectDictWorkgroupState } from 'models/dictionaries/dict-workgroup/selectors';
import {
    getSectorsListApi,
    getSectorsListByDictWorkGroup,
} from 'models/objects/sector/api';
import { ObjectResponseType } from 'models/objects/object/types';
import { getObjectsListApi } from 'models/objects/object/api';
import { SectorResponseType } from 'models/objects/sector/types';
import {
    createDictWorkGroupApi,
    updateDictWorkGroupApi,
} from 'models/dictionaries/dict-workgroup/api';
import {
    WorkgroupBaseType,
    WorkgroupResponseType,
} from 'models/objects/workgroup/types';
import {
    createWorkgroupApi,
    deleteWorkgroupApi,
    getWorkgroupListApi,
} from 'models/objects/workgroup/api';
import { WithRoleComponent } from 'components/with-role-component/with-role-component';

export const DictWorkgroupTable = () => {
    const [selectedItem, setSelectedItem] =
        useState<WorkgroupTableDataType | null>(null);
    const [tableData, settableData] = useState<WorkgroupTableDataType[]>([]);
    const [isWorkerDialogOpen, setIsWorkerDialogOpen] =
        useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const { workerList } = useAppSelector(selectDictWorkerState);
    const { dictWorkgroupList } = useAppSelector(selectDictWorkgroupState);
    const [sectorsList, setSectorsList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const toast = useRef<Toast>(null);

    const columns = [
        {
            title: 'Название бригады',
            field: 'name',
            frozen: false,
            sortable: true,
        },
        {
            title: 'Шифр',
            field: 'pressmark',
            frozen: false,
            sortable: true,
        },
        {
            title: 'Отв.исполнитель',
            field: 'chief',
            frozen: false,
        },
        {
            title: 'Бригадир',
            field: 'brigadier',
            frozen: false,
        },
        {
            title: 'Сварщики',
            field: 'welders',
            frozen: false,
        },
        {
            title: 'Участки',
            field: 'sectors',
            frozen: false,
        },
    ];

    const actionTemplate = (row: any) => (
        <div className="flex">
            <WithRoleComponent>
                <Button
                    text
                    severity="secondary"
                    icon="pi pi-pencil"
                    className="mr-4 p-0"
                    onClick={(event) => handleEditClick(row)}
                    disabled={isLoading}
                    data-role="btn_dictionary_edit"
                />
            </WithRoleComponent>
            <WithRoleComponent>
                <Button
                    text
                    severity="secondary"
                    icon="pi pi-trash"
                    className="p-0"
                    onClick={(event) => handleDeleteClick(row)}
                    disabled={isLoading}
                    data-role="btn_dictionary_delete"
                />
            </WithRoleComponent>
        </div>
    );

    const welderOptionTemplate = (option: DictWorkerResponseType) => {
        return (
            <div className="flex align-items-center">
                {option.stamp} - {option.second_name} {option.first_name}{' '}
                {option.third_name ?? ''}
            </div>
        );
    };

    const sectorOptionTemplate = (option: string) => {
        return <div className="flex align-items-center">{option}</div>;
    };

    const rowTemplate = (data: any, field: string) => {
        if (!data) {
            return <div className="flex">-</div>;
        }
        if (field === 'chief') {
            return (
                <div className="flex">
                    {data.documents} - {data.second_name} {data.first_name}{' '}
                    {data.third_name ?? ''}
                </div>
            );
        } else if (field === 'brigadier') {
            return (
                <div className="flex">
                    {data.stamp} - {data.second_name} {data.first_name}{' '}
                    {data.third_name ?? ''}
                </div>
            );
        } else if (field === 'welders') {
            return (
                <div className="flex">
                    <Dropdown
                        options={data}
                        optionLabel="name"
                        placeholder={`${
                            isLoading
                                ? 'Загрузка данных...'
                                : 'Кликните для просмотра'
                        }`}
                        itemTemplate={welderOptionTemplate}
                        disabled={isLoading}
                    />
                </div>
            );
        } else if (field === 'sectors') {
            return (
                <div className="flex">
                    <Dropdown
                        options={data}
                        placeholder={`${
                            isLoading
                                ? 'Загрузка данных...'
                                : 'Кликните для просмотра'
                        }`}
                        itemTemplate={sectorOptionTemplate}
                        disabled={isLoading}
                    />
                </div>
            );
        } else {
            return <div className="flex">{data}</div>;
        }
    };

    const handleEditClick = (item: WorkgroupTableDataType) => {
        setSelectedItem(item);
        setIsWorkerDialogOpen(true);
    };
    const handleDeleteClick = (item: WorkgroupTableDataType) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    };

    const handleUpdate = async (
        item_uuid: string,
        item: WorkgroupTableDataType,
        sectorsData: any
    ) => {
        const body = generateWorkGroupRequestBody(item);

        const updatedDictWg = await updateDictWorkGroupApi(item_uuid, body);
        if (updatedDictWg) {
            // создание бригад на добавленных участках
            for (const workgroup of sectorsData.dataToCreate) {
                const newWorkGroup: WorkgroupBaseType = {
                    name: workgroup.name,
                    chief: `${workgroup.chief.second_name} ${
                        workgroup.chief.first_name
                    } ${
                        workgroup.chief.third_name
                            ? workgroup.chief.third_name
                            : ''
                    }`,
                    sector_item: workgroup.sector_item,
                    pressmark: workgroup.pressmark,
                    welders: workgroup.welders,
                    brigadier: `${workgroup.brigadier.second_name} ${
                        workgroup.brigadier.first_name
                    } ${
                        workgroup.brigadier.third_name
                            ? workgroup.brigadier.third_name
                            : ''
                    }`,
                    dict_workgroup_item: item_uuid,
                };
                await createWorkgroupApi(newWorkGroup);
            }
            // удаление бригад со сброшенных участков
            for (const sector of sectorsData.dataToDelete) {
                const wgBySector = await getWorkgroupListApi(sector.item_uuid);
                const wgToDelete = wgBySector.results.filter(
                    (wg: WorkgroupResponseType) =>
                        wg.dict_workgroup_item === item_uuid
                )[0];
                if (wgToDelete) await deleteWorkgroupApi(wgToDelete.item_uuid);
            }

            toast.current?.show([
                {
                    severity: 'success',
                    detail: 'Строительная бригада обновлена',
                    life: 3000,
                },
            ]);
            dispatch(getDictWorkGroupList()).unwrap();
            setSelectedItem(null);
            setIsWorkerDialogOpen(false);
        }
    };
    const handleCreate = async (
        item: WorkgroupTableDataType,
        sectorsData: any
    ) => {
        const body = generateWorkGroupRequestBody(item);

        const newDictWg = await createDictWorkGroupApi(body);
        if (newDictWg) {
            for (const workgroup of sectorsData.dataToCreate) {
                const newWorkgroup = {
                    name: workgroup.name,
                    chief: `${workgroup.chief.second_name} ${
                        workgroup.chief.first_name
                    } ${
                        workgroup.chief.third_name
                            ? workgroup.chief.third_name
                            : ''
                    }`,
                    sector_item: workgroup.sector_item,
                    pressmark: workgroup.pressmark
                        ? workgroup.pressmark
                        : newDictWg.pressmark,
                    welders: workgroup.welders,
                    brigadier: `${workgroup.brigadier.second_name} ${
                        workgroup.brigadier.first_name
                    } ${
                        workgroup.brigadier.third_name
                            ? workgroup.brigadier.third_name
                            : ''
                    }`,
                    dict_workgroup_item: newDictWg.item_uuid,
                };
                await createWorkgroupApi(newWorkgroup);
            }
            toast.current?.show([
                {
                    severity: 'success',
                    detail: 'Строительная бригада создана',
                    life: 3000,
                },
            ]);
            dispatch(getDictWorkGroupList()).unwrap();
            setIsWorkerDialogOpen(false);
        }
    };

    const handleDelete = (item: WorkgroupTableDataType) => {
        setDeleteDialogOpen(false);
        dispatch(deleteDictWorkGroup(item.item_uuid!))
            .unwrap()
            .then((res) => {
                toast.current?.show([
                    {
                        severity: 'success',
                        detail: 'Строительная бригада удалена из базы',
                        life: 3000,
                    },
                ]);
                dispatch(getDictWorkGroupList()).unwrap();
                setSelectedItem(null);
            });
    };

    const handleClose = () => {
        setDeleteDialogOpen(false);
        setIsWorkerDialogOpen(false);
        setSelectedItem(null);
    };

    const generateTableData = (
        workgroupList: DictWorkGroupResponseType[],
        workerList: DictWorkerResponseType[]
    ): void => {
        const newTableData: WorkgroupTableDataType[] = [];
        workgroupList.forEach((wg: DictWorkGroupResponseType) => {
            const newWorkGroup: any = { ...wg };
            if (newWorkGroup.chief) {
                const chiefItem = workerList.find(
                    (w) => w.item_uuid === newWorkGroup.chief
                );
                newWorkGroup.chief = chiefItem;
            }
            if (newWorkGroup.brigadier) {
                const brigadierItem = workerList.find(
                    (w) => w.item_uuid === newWorkGroup.brigadier
                );
                newWorkGroup.brigadier = brigadierItem;
            }
            if (newWorkGroup.welders.length > 0) {
                const weldersList: DictWorkerResponseType[] = [];
                newWorkGroup.welders.forEach((workerId: string) => {
                    const welderItem = workerList.find(
                        (w) => w.item_uuid === workerId
                    );
                    if (welderItem) {
                        weldersList.push(welderItem);
                    }
                });
                newWorkGroup.welders = weldersList;
            }
            newWorkGroup.sectors = [];
            newTableData.push(newWorkGroup);
        });
        settableData(newTableData);
    };

    const generateWorkGroupRequestBody = (
        workGroupData: WorkgroupTableDataType
    ) => {
        const body: any = { ...workGroupData };
        body.chief = workGroupData.chief?.item_uuid;
        body.brigadier = workGroupData.brigadier?.item_uuid;
        if (workGroupData.welders.length > 0) {
            body.welders = [];
            workGroupData.welders.forEach((w: DictWorkerResponseType) =>
                body.welders.push(w.item_uuid)
            );
        }
        return body;
    };

    const generateSectorsList = async () => {
        const objects: any = await getObjectsListApi();
        const sortedObjects = objects.results.sort(
            (a: ObjectResponseType, b: ObjectResponseType) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                } else {
                    return a.name.localeCompare(b.name, 'ru', {
                        sensitivity: 'accent',
                        numeric: true,
                    });
                }
            }
        );
        const sectorsData: any = [];
        for (const object of sortedObjects) {
            const sectors: any = await getSectorsListApi(object.item_uuid);
            const sortedSectors = sectors.results.sort(
                (a: SectorResponseType, b: SectorResponseType) =>
                    a.begin! - a.begin!
            );
            sortedSectors.forEach((s: SectorResponseType) => {
                sectorsData.push({
                    ...s,
                    sectorName: `${
                        object.name.length > 40
                            ? object.name.substr(0, 40) + '...'
                            : object.name
                    }, ${s.begin! / 1000} - ${s.end! / 1000}`,
                });
            });
        }
        setSectorsList(sectorsData);
        setIsLoading(false);
    };

    useEffect(() => {
        // получаем список сотрудников для дальнейшей генерации бригад
        dispatch(getWorkerList()).unwrap();
        dispatch(getDictWorkGroupList()).unwrap();

        // Формирование списка участков
        generateSectorsList();
    }, []);

    useEffect(() => {
        if (dictWorkgroupList.length > 0 && workerList.length > 0) {
            generateTableData(dictWorkgroupList, workerList);
        }
    }, [workerList, dictWorkgroupList]);

    useEffect(() => {
        if (sectorsList.length > 0 && tableData.length > 0) {
            tableData.map((w: WorkgroupTableDataType) => {
                getSectorsListByDictWorkGroup(w.item_uuid!).then(
                    (response: any) => {
                        if (response.results.length > 0) {
                            response.results.forEach(
                                (s_wg: SectorResponseType) => {
                                    const sector = sectorsList.filter(
                                        (s: any) =>
                                            s.item_uuid === s_wg.item_uuid
                                    )[0].sectorName;
                                    w.sectors!.push(sector);
                                }
                            );
                        }
                        return w;
                    }
                );
            });
        }
    }, [sectorsList, tableData]);

    return (
        <div className="tableContainer">
            <Toast ref={toast} />
            <div className="flex justify-content-between align-items-center">
                <p className="block text-2xl font-bold">Строительные бригады</p>
                <div>
                    <WithRoleComponent>
                        <Button
                            color="primary"
                            onClick={() => setIsWorkerDialogOpen(true)}
                            disabled={isLoading}
                            data-role="btn_dictionary_create"
                        >
                            Добавить бригаду
                        </Button>
                    </WithRoleComponent>
                </div>
            </div>

            <DataTable
                value={tableData}
                scrollable
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                selectionMode="single"
                rowHover
                emptyMessage="Строительные бригады пока не добавлены"
                className="mt-2"
                sortField="name"
                sortOrder={1}
            >
                {columns.map((col, i) => (
                    <Column
                        key={col.field}
                        field={col.field}
                        header={col.title}
                        style={
                            col.field === 'welders' || col.field === 'sectors'
                                ? { minWidth: '180px' }
                                : { minWidth: '80px' }
                        }
                        frozen={!!col.frozen}
                        sortable={col.sortable}
                        body={(row) =>
                            rowTemplate(row[`${col.field}`], col.field)
                        }
                    />
                ))}
                <Column header="" body={actionTemplate} />
            </DataTable>
            {Boolean(isWorkerDialogOpen) && (
                <WorkgroupDialog
                    formData={selectedItem}
                    workerList={workerList}
                    workgroupList={dictWorkgroupList}
                    onClose={handleClose}
                    onUpdate={handleUpdate}
                    onCreate={handleCreate}
                    sectorsList={sectorsList}
                />
            )}
            {Boolean(deleteDialogOpen) && (
                <WorkgroupDeletingDialog
                    workgroupData={selectedItem}
                    onDelete={handleDelete}
                    onClose={handleClose}
                />
            )}
        </div>
    );
};
