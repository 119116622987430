import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUsersMe } from './actions';
import options from './options';
import { UsersMeResponseType } from './types';

const { name } = options;

type usersMeState = {
    isLoading: boolean;
    error: string;
    usersMe: UsersMeResponseType | null;
};

const initialState: usersMeState = {
    isLoading: false,
    error: '',
    usersMe: null,
};

export const usersMeSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearUsersMe(state) {
            state.usersMe = null;
        },
    },
    extraReducers: {
        [getUsersMe.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getUsersMe.fulfilled.type]: (
            state,
            { payload }: PayloadAction<UsersMeResponseType>
        ) => {
            state.isLoading = false;
            state.error = '';
            state.usersMe = payload;
        },
        [getUsersMe.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export default usersMeSlice.reducer;
