import React, { useEffect, useState, useMemo } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { useAppSelector } from 'store/hooks';
import { selectDomElementsForGroupState } from 'models/web-permissions/selectors';
import { selectUsersMeState } from 'models/users/selectors';
import { DomElementsForGroupResponseType } from 'models/web-permissions/types';

/* Метод для определения, должен ли компонент быть заблокирован */
export const isDisabledElementByGroup = (
    dataRole: string,
    domElementsForGroup: DomElementsForGroupResponseType[]
) => {
    return domElementsForGroup.some(
        (item) =>
            item.dom_element.string_ident === dataRole &&
            item.state_type.name === 'disabled'
    );
};

export const WithRoleComponent = ({ children }: any) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const { domElementsForGroup } = useAppSelector(
        selectDomElementsForGroupState
    );
    const [modifyElement, setModifyElement] = useState<any>();
    const { usersMe } = useAppSelector(selectUsersMeState);
    const tooltipId = useMemo(
        () => `tooltip-${Math.random().toString(36).substr(2, 9)}`,
        []
    );

    /* Отслеживание совйство disabled при смене группы пользователя */
    useEffect(() => {
        if (children.props['data-role']) {
            setIsDisabled(
                isDisabledElementByGroup(
                    children.props['data-role'],
                    domElementsForGroup
                )
            );
        }
    }, [children, usersMe]);

    /* Генерация окончательного компонента для отображения */
    useEffect(() => {
        setModifyElement(
            React.createElement(
                'div',
                {
                    'data-pr-tooltip': `Действие недоступно для вашей роли (${usersMe?.groups[0]}). Обратитесь к администратору`,
                    'data-pr-position': 'left',
                    id: `${tooltipId}`,
                },
                React.cloneElement(children, {
                    disabled: isDisabled,
                    style: {
                        ...children.props.style,
                    },
                }),
                // Tooltip добавляется только в случае если компонент заблокирован для пользовательской роли
                isDisabled &&
                    React.createElement(Tooltip, {
                        target: isDisabled ? `#${tooltipId}` : '',
                        disabled: !isDisabledElementByGroup(
                            children.props['data-role'],
                            domElementsForGroup
                        ),
                    })
            )
        );
    }, [isDisabled, children, tooltipId, usersMe]);

    return <>{modifyElement}</>;
};
