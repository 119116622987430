import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRadiographyContourCommentsListCumulativeApi } from './api';
import options from './options';

const { name } = options;

export const getRadiographyContourCommentsListCumulative = createAsyncThunk(
    `${name}/getRadiographyContourCommentsListCumulative`,
    async (_, thunkApi) => {
        try {
            return await getRadiographyContourCommentsListCumulativeApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
