import { WbLoader } from 'components';
import React, { Component, useEffect, useRef, useState } from 'react';
import '../defect-analysis.scss';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectRadiographyContourCommentsListCumulativeState } from 'models/cumulative/radiography_contour_comments_list_cumulative/selectors';
import { getRadiographyContourCommentsListCumulative } from 'models/cumulative/radiography_contour_comments_list_cumulative/actions';
import { getRadiographyGeneralCommentsListCumulative } from 'models/cumulative/radiography_general_comments_list_cumulative/actions';
import { selectRadiographyGeneralCommentsListCumulativeState } from 'models/cumulative/radiography_general_comments_list_cumulative/selectors';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import {
    deleteRadiographyGeneralComments,
    getRadiographyGeneralCommentsList,
    updateRadiographyGeneralComments,
} from 'models/feedback/radiography_general_comments/actions';
import {
    deleteRadiographyContourComments,
    updateRadiographyContourComments,
} from 'models/feedback/radiography_contour_comments/actions';
import { downloadDcomFile } from 'models/weldbooks/radiography/actions';
import { exportCSV } from 'models/export_csv/actions';
import { exportCSVType } from 'models/export_csv/types';
import { commentTypes, ICommentFilter, initialReasons } from '../helpers';
import { DefectAnalysisTable } from '../forms/defect-analysis-table';
import { DefectAnalysisToolbar } from '../forms/defect-analysis-toolbar';

const removeDuplicates = (arr: any[]) => {
    const seen = new Set();
    return arr.filter((obj) => {
        const key = JSON.stringify(obj);
        return seen.has(key) ? false : seen.add(key);
    });
};

const DefectAnalysisComponent = () => {
    const dispatch = useAppDispatch();
    const {
        isLoading: isGeneralListLoading,
        radiographyContourCommentsListCumulative,
    } = useAppSelector(selectRadiographyContourCommentsListCumulativeState);
    const {
        isLoading: isContourListLoading,
        radiographyGeneralCommentsListCumulative,
    } = useAppSelector(selectRadiographyGeneralCommentsListCumulativeState);
    const [commentsData, setCommentsData] = useState<any[]>([]);
    const [dateTimeOrder, setDateTimeOrder] = useState<string>(
        '-row_created_datetime'
    );
    const [filteredCommentsData, setFilteredCommentsData] = useState<any[]>([]);
    const [resonsFilter, setResonsFilter] =
        useState<ICommentFilter[]>(initialReasons);
    const [commentTypesFilter, setCommentTypesFilter] =
        useState<ICommentFilter[]>(commentTypes);

    const [markedState, setMarkedState] = useState<
        [{ name: string; value: boolean }, { name: string; value: boolean }]
    >([
        { name: 'marked', value: false },
        { name: 'unmarked', value: true },
    ]);
    const toast = useRef<Toast>(null);
    const [downloadProgressValue, setDownloadProgressValue] =
        useState<number>(0);
    const [isCSVFileLoading, setIsCSVFileLoading] = useState<boolean>(false);

    /* Метод для генерации данных для таблицы */
    const generateCommentsData = async () => {
        Promise.all([
            dispatch(getRadiographyContourCommentsListCumulative()),
            dispatch(getRadiographyGeneralCommentsListCumulative()),
        ]).then(([contourList, generalList]) => {
            const tableData: any[] = [
                ...contourList.payload.results,
                ...generalList.payload.results,
            ];
            setCommentsData(tableData);
        });
    };

    /* Метод для фильтрации данных таблицы */
    const filterCommentsData = () => {
        let newFilteredCommentsData: any[] = [];

        // Фильтрация по причинам отбраковки
        const activeResonsFilter = resonsFilter.filter(
            (reason) => reason.value
        );
        if (activeResonsFilter.length > 0) {
            activeResonsFilter.forEach((reason: ICommentFilter) => {
                let dataByFilter: any[] = [];
                if (reason.field === '-') {
                    dataByFilter = commentsData.filter(
                        (comment: any) =>
                            comment.radiography_contour_comments ||
                            (comment?.radiography_general_comments
                                ?.detect_qa === false &&
                                comment?.radiography_general_comments
                                    ?.detect_seam === false &&
                                comment?.radiography_general_comments
                                    ?.detect_defects === false &&
                                comment?.radiography_general_comments
                                    ?.detect_beltmarks === false &&
                                comment?.radiography_general_comments
                                    ?.calculate_defect_offset === false &&
                                comment?.radiography_general_comments
                                    ?.conclusion === false)
                    );
                } else {
                    dataByFilter = commentsData.filter(
                        (comment: any) =>
                            comment.radiography_general_comments?.[
                                `${reason.field}`
                            ] &&
                            comment.radiography_general_comments?.[
                                `${reason.field}`
                            ] === reason.value
                    );
                }

                newFilteredCommentsData.push(...dataByFilter);
            });
        } else {
            newFilteredCommentsData.push(...commentsData);
        }

        newFilteredCommentsData = removeDuplicates(newFilteredCommentsData);

        // Фильтрация по типам комментариев
        const activecommentTypesFilter = commentTypesFilter.filter(
            (reason) => reason.value
        );

        if (activecommentTypesFilter.length > 0) {
            if (activecommentTypesFilter.length === 1) {
                activecommentTypesFilter.forEach((reason: ICommentFilter) => {
                    newFilteredCommentsData = newFilteredCommentsData.filter(
                        (data) =>
                            reason.field === 'contour'
                                ? Object.hasOwn(
                                      data,
                                      'radiography_contour_comments'
                                  )
                                : !Object.hasOwn(
                                      data,
                                      'radiography_contour_comments'
                                  )
                    );
                });
            }
        }

        // Фильтрация по отметкам просмотрено/не просмотрено
        const activeMarkedStateFilter = markedState.filter(
            (reason) => reason.value
        );

        if (activeMarkedStateFilter.length > 0) {
            activeMarkedStateFilter.forEach((reason: any) => {
                newFilteredCommentsData = newFilteredCommentsData.filter(
                    (data) => {
                        const isMarked = reason.name === 'marked';
                        return data.radiography_contour_comments
                            ? data.radiography_contour_comments.mark ===
                                  isMarked
                            : data.radiography_general_comments.mark ===
                                  isMarked;
                    }
                );
            });
        }
        setFilteredCommentsData(newFilteredCommentsData);
    };

    /* Обработчик для выбора просмотренных/не просмотренных комментариев */
    const handleCheckMarked = (index: number, value: boolean) => {
        const newState: [
            { name: string; value: boolean },
            { name: string; value: boolean }
        ] = [
            { name: 'marked', value: false },
            { name: 'unmarked', value: false },
        ];
        newState[index].value = value;
        setMarkedState(newState);
    };

    /* Обработчик для пометки комментария как просмотренного/непросмотренного */
    const setCommentMarked = async (
        event: CheckboxChangeEvent,
        data: any,
        options: any
    ) => {
        event.originalEvent?.stopPropagation();

        let updatedComment: any;

        const isGeneralComment = !!data?.radiography_general_comments;

        let payload: any;
        if (isGeneralComment) {
            payload = await dispatch(
                updateRadiographyGeneralComments({
                    uuid: data.radiography_general_comments.item_uuid,
                    body: { mark: event.target.checked },
                })
            );
        } else {
            payload = await dispatch(
                updateRadiographyContourComments({
                    uuid: data.radiography_contour_comments.item_uuid,
                    body: { mark: event.target.checked },
                })
            );
        }
        updatedComment = { ...[...filteredCommentsData][options.rowIndex] };
        if (isGeneralComment) {
            updatedComment = {
                ...updatedComment,
                radiography_general_comments: payload.payload,
            };
        } else {
            updatedComment = {
                ...updatedComment,
                radiography_contour_comments: payload.payload,
            };
        }
        const updatedFilteredCommentsData = [...filteredCommentsData];

        updatedFilteredCommentsData[options.rowIndex] = updatedComment;

        setFilteredCommentsData(updatedFilteredCommentsData);

        toast.current?.show({
            severity: 'success',
            detail: `Комментарий был помечен как ${
                event.target.checked ? 'просмотренный' : 'не просмотренный'
            }`,
        });
        setTimeout(() => {
            generateCommentsData();
        }, 700);
    };

    /* Обработчик для скачивания файла DCOM */
    const downloadDcmFile = async (e: any, radiography: any) => {
        e.preventDefault();
        e.stopPropagation();
        await dispatch(
            downloadDcomFile({
                item_uuid: radiography.item_uuid,
                uploadProgress: (value: any) => {
                    setDownloadProgressValue(value);
                },
            })
        ).then((res: any) => {
            const blob = new Blob([res.payload.data], {
                type: 'application/octet-stream',
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${radiography.original_file_name}.DCM`;
            link.click();
            window.URL.revokeObjectURL(link.href);
            setDownloadProgressValue(0);
        });
    };

    /* Обработчик для удаления комментария */
    const deleteComment = async (data: any) => {
        const isGeneralComment = !!data.radiography_general_comments;
        if (isGeneralComment) {
            await dispatch(
                deleteRadiographyGeneralComments(
                    data.radiography_general_comments.item_uuid
                )
            );
        } else {
            await dispatch(
                deleteRadiographyContourComments(
                    data.radiography_contour_comments.item_uuid
                )
            );
        }
        toast.current?.show({
            severity: 'success',
            detail: `Комментарий удалён`,
        });
        await generateCommentsData();
    };

    /* Обработчик для скачивания файла CSV */
    const exportCSVFile = async () => {
        setIsCSVFileLoading(true);

        const body: exportCSVType = {};
        const contour_filters: any = {};
        const general_filters: any = {};

        const activeMarkedStateFilter = markedState.filter(
            (reason) => reason.value
        );

        if (activeMarkedStateFilter.length === 1) {
            contour_filters.mark = activeMarkedStateFilter[0].name === 'marked';
            general_filters.mark = contour_filters.mark;
        }
        const activeresonsFilter = resonsFilter.filter(
            (reason) => reason.value
        );

        if (activeresonsFilter.length > 0) {
            activeresonsFilter.forEach((reason: any) => {
                if (reason.name === '-') {
                    general_filters.detect_qa = false;
                    general_filters.detect_seam = false;
                    general_filters.detect_defects = false;
                    general_filters.detect_beltmarks = false;
                    general_filters.calculate_defect_offset = false;
                    general_filters.conclusion = false;
                } else {
                    general_filters[reason.field] = reason.value;
                }
            });
        }
        if (
            commentTypesFilter[0].value === false &&
            commentTypesFilter[1].value === false
        ) {
            body.contour_filters = contour_filters;
            body.general_filters = general_filters;
        } else {
            if (
                commentTypesFilter.some(
                    (reason: any) => reason.value && reason.field === 'contour'
                )
            ) {
                body.contour_filters = contour_filters;
            }

            if (
                commentTypesFilter.some(
                    (reason: any) => reason.value && reason.field === 'object'
                )
            ) {
                body.general_filters = general_filters;
            }
        }
        body.ordering = dateTimeOrder;

        const date = new Date().toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        await dispatch(exportCSV(body))
            .unwrap()
            .then((response: any) => {
                const url = URL.createObjectURL(response);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `Выгрузка комментариев - ${date}.csv`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
        setIsCSVFileLoading(false);
    };

    useEffect(() => {
        generateCommentsData();
    }, []);

    useEffect(() => {
        filterCommentsData();
    }, [commentsData, markedState]);

    return (
        <>
            <div className="m-4 mt-2">
                {isGeneralListLoading && isContourListLoading && <WbLoader />}
                <>
                    <DefectAnalysisToolbar
                        onExportCSV={exportCSVFile}
                        isCSVFileLoading={isCSVFileLoading}
                    />
                    <DefectAnalysisTable
                        data={filteredCommentsData}
                        resonsFilter={resonsFilter}
                        commentTypesFilter={commentTypesFilter}
                        markedState={markedState}
                        downloadProgressValue={downloadProgressValue}
                        onChangeReasonsFilter={(data: ICommentFilter[]) => {
                            setResonsFilter(data);
                            setTimeout(() => {
                                filterCommentsData();
                            }, 200);
                        }}
                        onChangeCommentTypesFilter={(
                            data: ICommentFilter[]
                        ) => {
                            setCommentTypesFilter(data);
                            setTimeout(() => {
                                filterCommentsData();
                            }, 200);
                        }}
                        onCheckMarked={handleCheckMarked}
                        onSetCommentMarked={setCommentMarked}
                        onDownloadDcmFile={downloadDcmFile}
                        onDeleteComment={async (data) => deleteComment(data)}
                        onSetDateTimeordering={setDateTimeOrder}
                    />
                </>
                <Toast ref={toast} />
            </div>
        </>
    );
};

export default DefectAnalysisComponent;
