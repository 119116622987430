import request from 'utils/http/request';
import { getUrl } from 'models/utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getAllRadiographyCommentsCumulativeApi = async (
    radiography_uuid: string
): Promise<any> => {
    return request.get(url(''));
};
