import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllRadiographyCommentsCumulative } from './actions';
import options from './options';

const { name } = options;

type allRadiographyCommentsCumulativeState = {
    isLoading: boolean;
    error: string;
    allRadiographyCommentsCumulative: any[];
};

const initialState: allRadiographyCommentsCumulativeState = {
    isLoading: false,
    error: '',
    allRadiographyCommentsCumulative: [],
};

export const allRadiographyCommentsCumulativeSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearallRadiographyCommentsCumulative(state) {
            state.allRadiographyCommentsCumulative = [];
        },
    },
    extraReducers: {
        [getAllRadiographyCommentsCumulative.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getAllRadiographyCommentsCumulative.fulfilled.type]: (
            state,
            { payload }: PayloadAction<any>
        ) => {
            state.isLoading = false;
            state.error = '';
            state.allRadiographyCommentsCumulative = payload.results;
        },
        [getAllRadiographyCommentsCumulative.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export default allRadiographyCommentsCumulativeSlice.reducer;
