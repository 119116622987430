import React, { useContext, useRef, useState, useEffect } from 'react';
import { Menu } from 'primereact/menu';
import { buildingInfo } from './building-info';

import AuthContext from '../../contexts/auth';
import { MenuItem } from 'primereact/menuitem';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { usersMeSlice } from 'models/users/slice';
import { domElementsForGroupSlice } from 'models/web-permissions/slice';
const fs = require('fs');

export const UserMenu: React.FC = () => {
    const menu = useRef<Menu>(null);
    const { onLogout } = useContext(AuthContext);
    const [isSystemInfoOpen, setIsSystemInfoOpen] = useState<boolean>(false);
    const [items, setItems] = useState<MenuItem[]>([
        {
            label: 'Версия системы',
            icon: 'pi pi-info-circle',
            command: () => setIsSystemInfoOpen(true),
        },
        {
            label: 'Выход',
            icon: 'pi pi-sign-out',
            command: () => onLogoutClick(),
        },
    ]);
    const { clearUsersMe } = usersMeSlice.actions;
    const { cleardomElementsForGroup } = domElementsForGroupSlice.actions;
    const onLogoutClick = () => {
        onLogout && onLogout();
        clearUsersMe();
        cleardomElementsForGroup();
    };

    useEffect(() => {
        // if (buildingInfo.build_id) {
        //     const newItems = [...items];
        //     newItems.unshift({
        //         label: `Номер сборки: ${buildingInfo.build_id}`,
        //         disabled: true,
        //     });
        //     setItems(newItems);
        // }
    }, []);

    return (
        <div>
            <i
                className="pi pi-user cursor-pointer text-700"
                onClick={(event) => menu!.current!.toggle(event)}
            />
            <Menu
                model={items}
                popup
                ref={menu}
                id="user-menu"
                popupAlignment="left"
            />
            <Dialog
                header="Версия системы"
                visible={isSystemInfoOpen}
                style={{ width: '30vw' }}
                onHide={() => setIsSystemInfoOpen(false)}
                closable={false}
            >
                <p className="m-0">
                    <p>Система искусственного анализа.</p>
                    <br />
                    <p>Номер версии: 2.0</p>
                    <br />
                    <p>
                        Номер сборки:{' '}
                        {buildingInfo?.build_id
                            ? buildingInfo.build_id
                            : 'Не определено'}
                    </p>
                </p>
                <div className="flex w-full justify-content-end mt-2">
                    <Button
                        label="Закрыть"
                        onClick={() => setIsSystemInfoOpen(false)}
                        className="p-button-sm"
                    />
                </div>
            </Dialog>
        </div>
    );
};
