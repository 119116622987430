import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { RadiographyGeneralCommentsResponseType } from './types';
import {
    deleteRadiographyGeneralComments,
    getRadiographyGeneralCommentsById,
    getRadiographyGeneralCommentsList,
    updateRadiographyGeneralComments,
} from './actions';
import { IPagingSearch } from '../../../interfaces';

const { name } = options;

type RadiographyGeneralCommentsState = {
    radiographyGeneralCommentsList: RadiographyGeneralCommentsResponseType[];
    isLoading: boolean;
    error: string;
};

const initialState: RadiographyGeneralCommentsState = {
    radiographyGeneralCommentsList: [],
    isLoading: false,
    error: '',
};

export const RadiographyGeneralCommentsSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearRadiographyGeneralCommentsList(state) {
            state.radiographyGeneralCommentsList = [];
        },
    },
    extraReducers: {
        [getRadiographyGeneralCommentsList.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getRadiographyGeneralCommentsList.fulfilled.type]: (
            state,
            {
                payload,
            }: PayloadAction<
                IPagingSearch<RadiographyGeneralCommentsResponseType>
            >
        ) => {
            state.isLoading = false;
            state.radiographyGeneralCommentsList = payload.results;
            state.error = '';
        },
        [getRadiographyGeneralCommentsList.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.radiographyGeneralCommentsList = [];
            state.error = payload;
        },
        [getRadiographyGeneralCommentsById.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getRadiographyGeneralCommentsById.fulfilled.type]: (
            state,
            { payload }: PayloadAction<RadiographyGeneralCommentsResponseType>
        ) => {
            state.isLoading = false;
            state.error = '';
        },
        [getRadiographyGeneralCommentsById.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
        [updateRadiographyGeneralComments.pending.type]: (state) => {
            state.isLoading = true;
        },
        [updateRadiographyGeneralComments.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [updateRadiographyGeneralComments.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
        [deleteRadiographyGeneralComments.pending.type]: (state) => {
            state.isLoading = true;
        },
        [deleteRadiographyGeneralComments.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.error = '';
        },
        [deleteRadiographyGeneralComments.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export default RadiographyGeneralCommentsSlice.reducer;
