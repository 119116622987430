import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRadiographyGeneralCommentsListCumulativeApi } from './api';
import options from './options';

const { name } = options;

export const getRadiographyGeneralCommentsListCumulative = createAsyncThunk(
    `${name}/getRadiographyGeneralCommentsListCumulative`,
    async (_, thunkApi) => {
        try {
            return await getRadiographyGeneralCommentsListCumulativeApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
