export interface ICommentFilter {
    field: string;
    name: string;
    value: boolean;
}

export const initialReasons: ICommentFilter[] = [
    {
        field: 'detect_qa',
        name: 'Неудовлетворительное качество снимка',
        value: false,
    },
    {
        field: 'detect_seam',
        name: 'Неправильно выполнено распознавание шва',
        value: false,
    },
    {
        field: 'detect_defects',
        name: 'Неправильно выполнено распознавание дефектов',
        value: false,
    },
    {
        field: 'detect_beltmarks',
        name: 'Неправильно выполнено распознавание меток мерного пояса',
        value: false,
    },
    {
        field: 'calculate_defect_offset',
        name: 'Неправильно выполнено вычисление смещения дефектов',
        value: false,
    },
    {
        field: 'conclusion',
        name: 'Неверное заключение по КСС',
        value: false,
    },
    {
        field: '-',
        name: '-',
        value: false,
    },
];

export const commentTypes: ICommentFilter[] = [
    {
        field: 'contour',
        name: 'к объекту',
        value: false,
    },
    {
        field: 'object',
        name: 'к снимку',
        value: false,
    },
];
