import { generateConclusiontApi } from 'models/report/conclusion/api';
import {
    ConclusionType,
    firstStepType,
    secondStepType,
    thirdStepType,
    fourthStepType,
} from 'models/report/conclusion/types';
import { JobBaseType } from 'models/weldbooks/job/types';
import { RadiographyResponseType } from 'models/weldbooks/radiography/types';
import { formatDate } from 'utils/date';

export const jobCreateInitialValues: JobBaseType = {
    weldbook_item: '',
    workgroup_item: '',
    datetime: new Date(Date.now()),
    stage: 'first',
    iteration: null,
    air_temp: null,
    prepare_temp: 150,
    welding_type: 'Ручная дуговая сварка',
    status: 'new',
};

export const exportInitialValues: ConclusionType = {
    radiography_uuid: '',
};

export const exportFirstStepValidate = (value: firstStepType, form: any) => {
    const errors: any = {};
    if (
        (!value.laboratory_name || !value.laboratory_name.toString().trim()) &&
        form.touched.laboratory_name
    ) {
        errors.laboratory_name = 'Обязательное поле ввода';
    }
    if (
        (!value.laboratory_certification ||
            !value.laboratory_certification.toString().trim()) &&
        form.touched.laboratory_certification
    ) {
        errors.laboratory_certification = 'Обязательное поле ввода';
    }
    if (
        (!value.laboratory_regulatory_document ||
            !value.laboratory_regulatory_document.toString().trim()) &&
        form.touched.laboratory_regulatory_document
    ) {
        errors.laboratory_regulatory_document = 'Обязательное поле ввода';
    }
    if (
        (!value.laboratory_otc || !value.laboratory_otc.toString().trim()) &&
        form.touched.laboratory_otc
    ) {
        errors.laboratory_otc = 'Обязательное поле ввода';
    }
    if (
        (!value.radiography_equipment_emission ||
            !value.radiography_equipment_emission.toString().trim()) &&
        form.touched.radiography_equipment_emission
    ) {
        errors.radiography_equipment_emission = 'Обязательное поле ввода';
    }
    if (
        (!value.radiography_equipment_type ||
            !value.radiography_equipment_type.toString().trim()) &&
        form.touched.radiography_equipment_type
    ) {
        errors.radiography_equipment_type = 'Обязательное поле ввода';
    }
    if (
        !value.radiography_equipment_protective_screen ||
        !value.radiography_equipment_protective_screen.toString().trim()
    ) {
        errors.radiography_equipment_protective_screen =
            'Обязательное поле ввода';
    }
    if (
        !value.radiography_equipment_reinforcing_screen ||
        !value.radiography_equipment_reinforcing_screen.toString().trim()
    ) {
        errors.radiography_equipment_reinforcing_screen =
            'Обязательное поле ввода';
    }
    if (
        !value.laboratory_visual_number ||
        !value.laboratory_visual_number.toString().trim()
    ) {
        errors.laboratory_visual_number = 'Обязательное поле ввода';
    }
    if (!value.laboratory_visual_date) {
        errors.laboratory_visual_date = 'Обязательное поле ввода';
    }
    return errors;
};

export const exportSecondStepValidate = (value: secondStepType, form: any) => {
    const errors: any = {};
    if (!value.object_name || !value.object_name.toString().trim()) {
        errors.object_name = 'Обязательное поле ввода';
    }
    if (!value.sector_name || !value.sector_name.toString().trim()) {
        errors.sector_name = 'Обязательное поле ввода';
    }
    if (
        !value.weldbook_quality_category ||
        !value.weldbook_quality_category.toString().trim()
    ) {
        errors.weldbook_quality_category = 'Обязательное поле ввода';
    }
    if (!value.sector_begin_end || !value.sector_begin_end.toString().trim()) {
        errors.sector_begin_end = 'Обязательное поле ввода';
    }
    if (
        !value.object_quality_category ||
        !value.object_quality_category.toString().trim()
    ) {
        errors.object_quality_category = 'Обязательное поле ввода';
    }
    if (
        (!value.object_contractor ||
            !value.object_contractor.toString().trim()) &&
        form.touched.object_contractor
    ) {
        errors.object_contractor = 'Обязательное поле ввода';
    }
    if (!value.object_customer || !value.object_customer.toString().trim()) {
        errors.object_customer = 'Обязательное поле ввода';
    }
    if (
        (!value.sector_workgroup_pressmark ||
            !value.sector_workgroup_pressmark.toString().trim()) &&
        form.touched.sector_workgroup_pressmark
    ) {
        errors.sector_workgroup_pressmark = 'Обязательное поле ввода';
    }
    return errors;
};

export const exportThirdStepValidate = (value: thirdStepType, form: any) => {
    const errors: any = {};
    if (!value.issue_number || !value.issue_number.toString().trim()) {
        errors.issue_number = 'Обязательное поле ввода';
    }
    if (!value.weldbook_number || !value.weldbook_number.toString().trim()) {
        errors.weldbook_number = 'Обязательное поле ввода';
    }
    if (
        !value.weldbook_element_diameter ||
        !value.weldbook_element_diameter.toString().trim()
    ) {
        errors.weldbook_element_diameter = 'Обязательное поле ввода';
    }
    if (
        !value.control_sensitivity ||
        !value.control_sensitivity.toString().trim()
    ) {
        errors.control_sensitivity = 'Обязательное поле ввода';
    }
    if (!value.executor_issue_date && form.touched.executor_issue_date) {
        errors.executor_issue_date = 'Обязательное поле ввода';
    }
    return errors;
};

export const exportFourthStepValidate = (value: fourthStepType, form: any) => {
    const errors: any = {};
    if (!value.executor_name || !value.executor_name.toString().trim()) {
        errors.executor_name = 'Обязательное поле ввода';
    }
    if (!value.issuer_name || !value.issuer_name.toString().trim()) {
        errors.issuer_name = 'Обязательное поле ввода';
    }
    if (
        !value.executor_qualification_level ||
        !value.executor_qualification_level.toString().trim()
    ) {
        errors.executor_qualification_level = 'Обязательное поле ввода';
    }
    if (
        !value.issuer_qualification_level ||
        !value.issuer_qualification_level.toString().trim()
    ) {
        errors.issuer_qualification_level = 'Обязательное поле ввода';
    }
    if (
        !value.executor_qualification_number ||
        !value.executor_qualification_number.toString().trim()
    ) {
        errors.executor_qualification_number = 'Обязательное поле ввода';
    }
    if (
        !value.issuer_qualification_number ||
        !value.issuer_qualification_number.toString().trim()
    ) {
        errors.issuer_qualification_number = 'Обязательное поле ввода';
    }
    return errors;
};

export const weldQualits = ['A', 'B'];

export const weldTypes = [
    'Ручная дуговая сварка',
    'Автоматическая сварка',
    'Полуавтоматическая сварка',
    'Аргоновая сварка',
];

interface ITaskItem {
    name: string;
    step: string;
}

export const initialTaskItems: ITaskItem[] = [
    {
        name: 'Оценка качества снимка',
        step: 'awaiting',
    },
    {
        name: 'Распознавание шва',
        step: 'awaiting',
    },
    {
        name: 'Распознавание дефектов',
        step: 'awaiting',
    },
];

export const generateInitialTaskItems = (
    fileType: string,
    diameter: number
) => {
    const taskItems = [
        {
            name: 'Оценка качества снимка',
            step: 'awaiting',
        },
        {
            name: 'Распознавание шва',
            step: 'awaiting',
        },
        {
            name: 'Распознавание дефектов',
            step: 'awaiting',
        },
    ];

    if (diameter > 108 && fileType !== 'vzm') {
        taskItems.push(
            {
                name: 'Распознавание меток мерного пояса',
                step: 'awaiting',
            },
            {
                name: 'Вычисление смещения дефектов',
                step: 'awaiting',
            }
        );
    }
    return taskItems;
};

export const fileTypes = [
    { value: 'auto', name: 'Авто', disabled: false },
    { value: 'transcan', name: 'Транскан', disabled: false },
    { value: 'cifracon', name: 'Цифракон', disabled: false },
    // { value: 'vzm', name: 'VMZ', disabled: false },
];
export const convertFileType = (fileType: string): string =>
    fileType === 'transcan' ? 'Транскан' : 'Цифракон';
export const screeningSchemes = ['Панорамное', 'Фронтальное'];

export const printConclusion = (
    options: ConclusionType,
    radiography: RadiographyResponseType,
    setExportDialogOpen: any
) => {
    const object_name =
        options.object_name!.length > 50
            ? options.object_name!.substring(0, 50)
            : options.object_name;

    options.executor_issue_date = formatDate(
        options.executor_issue_date,
        'dd.MM.yyyy'
    );
    options.laboratory_visual_date = formatDate(
        options.laboratory_visual_date,
        'dd.MM.yyyy'
    );
    options.issue_date = options.executor_issue_date;
    options.issuer_issue_date = options.executor_issue_date;
    options.issuer_name = `${options.issuer_name.second_name} ${options.issuer_name.first_name} ${options.issuer_name.third_name}`;
    options.executor_name = `${options.executor_name.second_name} ${options.executor_name.first_name} ${options.executor_name.third_name}`;
    generateConclusiontApi({
        ...options,
        radiography_uuid: radiography!.item_uuid,
    }).then((response: any) => {
        const url = URL.createObjectURL(response);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${object_name}_${options.issue_number}_${options.executor_issue_date}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return setExportDialogOpen(false);
    });
};

export const getConclusionClass = (classes: any, value: any) => {
    switch (value) {
        case null:
            return classes.checkingTag;
            break;
        case undefined:
            return classes.checkingTag;
            break;
        case 'Ремонт':
            return classes.uncompleteTag;
            break;
        case 'Вырезать':
            return classes.errorTag;
            break;
        case 'Годен':
            return classes.completeTag;
            break;
        default:
            return true;
    }
};

export const getConclusionName = (value: any) => {
    let status: string = '';
    switch (value) {
        case null:
            status = '-';
            break;
        case 'new':
            status = '-';
            break;
        case 'repair':
            status = 'Ремонт';
            break;
        case 'cut':
            status = 'Вырезать';
            break;
        case 'fit':
            status = 'Годен';
            break;
        default:
    }
    return status;
};

export interface IThdItem {
    type: string;
    value: number;
    isIncluded: boolean;
}
export function conf_thd_tree_initial(): IThdItem[] {
    return [
        { type: 'Aa', value: 0.273, isIncluded: true },
        { type: 'Ak', value: 0.05, isIncluded: true },
        { type: 'Ba', value: 0.174, isIncluded: true },
        { type: 'Bd1', value: 0.185, isIncluded: true },
        { type: 'Da1', value: 0.295, isIncluded: true },
        { type: 'Dc1', value: 0.196, isIncluded: true },
        { type: 'Fa', value: 0.253, isIncluded: true },
        { type: 'Fc', value: 0.181, isIncluded: true },
        { type: 'Ob2', value: 0.068, isIncluded: true },
        { type: 'Ob3', value: 0.454, isIncluded: true },
        { type: 'Ob4', value: 0.26, isIncluded: true },
    ];
}

export const generateDefectConfThdTreeConf = (thdTree: any[]) => {
    // формирование конфига порога для запроса расчетных задач
    const thdTreeDataToRequest: any = {};
    thdTree.forEach((item: IThdItem) => {
        if (item.isIncluded) {
            thdTreeDataToRequest[`${item.type}`] = item.value;
        }
    });
    return thdTreeDataToRequest;
};
