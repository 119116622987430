import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Outlet, useNavigate } from 'react-router-dom';

import { DataTree } from 'containers/data-tree';
import { ItemDetail } from 'containers/item-detail';
import ErrorBoundary from '../../components/error-boundary';
import { AppTopBar } from '../app-top-bar';
import { AppContainer } from '../app-container/app-container';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getDomElementsForGroup } from 'models/web-permissions/actions';
import { selectDomElementsForGroupState } from 'models/web-permissions/selectors';
import { selectUsersMeState } from 'models/users/selectors';
import { getUsersMe } from 'models/users/actions';

const useStyles = makeStyles(() => ({
    layoutWrapper: {
        height: '100%',
        // width: '100%',
        backgroundColor: 'var(--main-surface-background)',
    },
    contentWrapper: {
        height: `calc(100% - 64px)`,
        overflowY: 'hidden',

        '& > div': {
            width: 'unset',
            padding: '0',
        },
    },
}));

export default () => {
    const classes = useStyles();
    const [page, setPage] = useState<string>('');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isLoading: isUsersMeLoading, usersMe } =
        useAppSelector(selectUsersMeState);
    const { isLoading: isDomElementsLoading, domElementsForGroup } =
        useAppSelector(selectDomElementsForGroupState);

    useEffect(() => {
        setPage(window.location.href);
    }, [window.location.href]);
    useEffect(() => {
        if (usersMe) {
            dispatch(getDomElementsForGroup(usersMe.groups[0]));
        }
    }, [usersMe]);
    useEffect(() => {
        dispatch(getUsersMe());
    }, []);
    if (!isDomElementsLoading) {
        if (page.length > 0) {
            if (page.includes('editor') || page.includes('defect-analysis')) {
                return (
                    <div className={`grid ${classes.layoutWrapper}`}>
                        <ErrorBoundary navigate={navigate}>
                            <AppTopBar />
                            <div
                                className={`col-12 ${classes.contentWrapper} p-0`}
                            >
                                <AppContainer>
                                    <Outlet />
                                </AppContainer>
                            </div>
                        </ErrorBoundary>
                    </div>
                );
            } else {
                return (
                    <div className={`grid ${classes.layoutWrapper}`}>
                        <ErrorBoundary navigate={navigate}>
                            <AppTopBar />
                            <div className="col-2 flex flex-column p-0 main-sidebar">
                                <DataTree />
                                <ItemDetail />
                            </div>
                            <div
                                className={`col-10 ${classes.contentWrapper} p-0`}
                            >
                                <AppContainer>
                                    <Outlet />
                                </AppContainer>
                            </div>
                        </ErrorBoundary>
                    </div>
                );
            }
        }
    }

    return null;
};
