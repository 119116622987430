import Private from 'containers/private';
import { Route } from 'react-router-dom';
import DefectAnalysisComponent from './components/defect-analysis-component';
import './defect-analysis.scss';

export default (
    <Route
        element={
            <Private>
                <DefectAnalysisComponent />
            </Private>
        }
        path="/defect-analysis"
        key="defect-analysis"
    />
);
