import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Auth from '../../contexts/auth';
import { useAppSelector } from 'store/hooks';
import { selectUsersMeState } from 'models/users/selectors';

type PrivateType = {
    redirectPath?: string;
    roles?: number[];
    children?: any;
};

export default ({
    redirectPath = '/',
    roles = [],
    children,
}: PrivateType): JSX.Element => {
    const { user } = useContext(Auth);
    const location = useLocation();
    const { isLoading: isUsersMeLoading, usersMe } =
        useAppSelector(selectUsersMeState);

    /* check roles here */
    // Запрещаем доступ к странице с анализом дефектов для роли "Пользователь"
    if (
        !user ||
        (children?.type?.name === 'DefectAnalysisComponent' &&
            usersMe?.groups[0] === 'Пользователь')
    ) {
        // сохранияем страницу на которой находимся, чтобы после переавторизации вернуться на нее
        return (
            <Navigate
                to={redirectPath}
                replace
                state={{ from: `${location.pathname}${location.search || ''}` }}
            />
        );
    }

    return children ? children : <Outlet />;
};
