import { useContext } from 'react';
import ErrorBoundary from 'components/error-boundary';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { WithRouter } from './containers/with-router';
import AuthContext from './contexts/auth';
import Auth from './pages/authorization/forms/auth';
import PrivateRoutes from './private-routes';

export default () => {
    const { user } = useContext(AuthContext);

    return (
        <ErrorBoundary navigate={useNavigate()}>
            <WithRouter>
                <Routes>
                    <Route element={<Auth />} key="auth" path="auth" />
                    {PrivateRoutes}
                    <Route
                        element={<Navigate to={user ? '/' : '/auth'} />}
                        path="*"
                        key="redirect"
                    />
                </Routes>
            </WithRouter>
        </ErrorBoundary>
    );
};
