import { ReactElement, useEffect, useRef } from 'react';
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { Toast } from 'primereact/toast';
import ApiContext from 'contexts/api';
import { registerHttpClient } from './request';
import HttpClient from './http-client';
import { getTokenString } from './utils';
import { useAppDispatch } from '../../store/hooks';
import { checkAuth } from '../../models/auth/actions';

export default ({ children }: { children: ReactElement }) => {
    const dispatch = useAppDispatch();
    const toast = useRef<Toast>(null);

    useEffect(() => {
        HttpClient.instance.interceptors.request.use(
            (config: InternalAxiosRequestConfig<any>) => {
                if (config.headers) {
                    config.headers.Authorization = getTokenString();
                }
                return config;
            },
            (error: any) => {
                toast.current?.show([
                    {
                        severity: 'error',
                        detail: `${error.message}`,
                        life: 3000,
                    },
                ]);
                return error;
            }
        );

        HttpClient.instance.interceptors.response.use(
            (config: AxiosResponse) => {
                return config;
            },
            async (error: AxiosError & { config: { isRetry: boolean } }) => {
                if (
                    error.response &&
                    error.response.config.url?.indexOf('manual') === -1
                ) {
                    const e = error.response as any;
                    // todo remove this
                    const originalRequest = error.config;

                    switch (true) {
                        case e.status === 401 && !error.config.isRetry: {
                            originalRequest.isRetry = true;
                            await dispatch(checkAuth());
                            return HttpClient.instance.request(originalRequest);
                        }
                        case e.status === 403: {
                            toast.current?.show([
                                {
                                    severity: 'error',
                                    summary: `Ошибка ${e.status}`,
                                    detail: `Нет доступа для данной операции`,
                                    life: 3000,
                                },
                            ]);
                            break;
                        }
                        case e.status === 400: {
                            if (e.data.non_field_errors) {
                                Object.keys(e.data.non_field_errors).map(
                                    (key: any) => {
                                        for (const err of e.data
                                            .non_field_errors) {
                                            toast.current?.show([
                                                {
                                                    severity: 'error',
                                                    summary: `Ошибка ${e.status}`,
                                                    detail: `${err}`,
                                                    life: 7000,
                                                },
                                            ]);
                                        }
                                    }
                                );
                            } else if (e.data.detail) {
                                toast.current?.show([
                                    {
                                        severity: 'error',
                                        summary: `Ошибка ${e.status}`,
                                        detail: `${e.data.detail}`,
                                        life: 7000,
                                    },
                                ]);
                            } else {
                                toast.current?.show([
                                    {
                                        severity: 'error',
                                        summary: `Ошибка ${e.status}`,
                                        detail: e.statusText,
                                        life: 7000,
                                    },
                                ]);
                            }

                            break;
                        }
                        case e.status === 404: {
                            toast.current?.show([
                                {
                                    severity: 'error',
                                    summary: `Ошибка ${e.status}`,
                                    detail: `${e.data.detail}`,
                                    life: 3000,
                                },
                            ]);
                            break;
                        }

                        case e.status === 500 || e.status === 502: {
                            toast.current?.show([
                                {
                                    severity: 'error',
                                    summary: `Ошибка ${e.status}`,
                                    detail: `Внутренняя ошибка сервера`,
                                    life: 3000,
                                },
                            ]);
                            break;
                        }

                        case e.status === 504: {
                            toast.current?.show([
                                {
                                    severity: 'error',
                                    summary: `Ошибка ${e.status}`,
                                    detail: `Сервер не смог ответить вовремя`,
                                    life: 3000,
                                },
                            ]);
                            break;
                        }
                        case e.status
                            .toString()
                            .indexOf('ERR_CONNECTION_RESET') !== -1: {
                            toast.current?.show([
                                {
                                    severity: 'error',
                                    detail: `Ошибка ERR_CONNECTION_RESET. Браузер не может установить соединение с удаленным сервером`,
                                    life: 3000,
                                },
                            ]);
                            break;
                        }
                        default: {
                            toast.current?.show([
                                {
                                    severity: 'error',
                                    summary: `Ошибка ${e.status}`,
                                    detail: `Неизвестная ошибка`,
                                    life: 3000,
                                },
                            ]);
                        }
                    }
                }
                throw error;
            }
        );

        registerHttpClient(HttpClient);
        // eslint-disable-next-line
    }, []);

    return (
        <ApiContext.Provider value={{}}>
            <Toast ref={toast} />
            {children}
        </ApiContext.Provider>
    );
};
