import request from 'utils/http/request';
import { exportCSVType } from './types';
import { getUrl } from '../utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const exportCSVApi = async (body: exportCSVType): Promise<any> => {
    return request.post(url(''), body, { responseType: 'blob' });
};
