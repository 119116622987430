import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDomElementsForGroup } from './actions';
import options from './options';
import { DomElementsForGroupResponseType } from './types';

const { name } = options;

type DomElementsForGroupState = {
    isLoading: boolean;
    error: string;
    domElementsForGroup: DomElementsForGroupResponseType[];
};

const initialState: DomElementsForGroupState = {
    isLoading: true,
    error: '',
    domElementsForGroup: [],
};

export const domElementsForGroupSlice = createSlice({
    name,
    initialState,
    reducers: {
        cleardomElementsForGroup(state) {
            state.domElementsForGroup = [];
        },
    },
    extraReducers: {
        [getDomElementsForGroup.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getDomElementsForGroup.fulfilled.type]: (
            state,
            { payload }: PayloadAction<any>
        ) => {
            state.isLoading = false;
            state.error = '';
            state.domElementsForGroup = payload;
        },
        [getDomElementsForGroup.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export default domElementsForGroupSlice.reducer;
