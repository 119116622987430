import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
import { IDictionaryItem, dictionariesItems } from './constants';
import './index.scss';
import { DictWorkerTable } from './forms/worker/worker-table';
import { DictWorkgroupTable } from './forms/workgroup/workgroup-table';
import { RadiographyEquipTable } from './forms/radiography-equipment/radiography-equipment-table';
import { DictFactoryIndexTable } from './forms/factory-index/factory-index-table';
import { DictFactoryNameTable } from './forms/factory-name/factory-name-table';
import { DictElementTypeTable } from './forms/element-type/element-type-table';
import { DictStandardDocumentTable } from './forms/standard-document/standard-document-table';
import { DictSteelGradeTable } from './forms/steel-grade/steel-grade-table';
import { DictWeldQaResponsibleTable } from './forms/weld-qa-responsible/weld-qa-responsible-table';
import { DictTechnicalSheetTable } from './forms/technical-sheet/technical-sheet-table';

interface IDictionariesComponent {
    onClose: () => void;
}

export const DictionariesComponent: React.FC<IDictionariesComponent> = ({
    onClose,
}) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [selectedDictionary, setSelectedDictionary] =
        useState<IDictionaryItem | null>(null);

    const selectItem = (dictioanary: IDictionaryItem) => {
        setSelectedDictionary(dictioanary);
    };

    const handleClose = () => {
        setVisible(false);
        onClose();
    };

    useEffect(() => {
        setVisible(true);
    }, []);

    return (
        <Dialog
            header="Справочники"
            visible={visible}
            style={{ width: '100vw', height: '95%', maxHeight: 'unset' }}
            onHide={handleClose}
            position="bottom"
            draggable={false}
        >
            <div className="grid max-w-full">
                <div className="col-2">
                    <ListBox
                        value={selectedDictionary}
                        onChange={(e) => selectItem(e.value)}
                        options={dictionariesItems}
                        optionLabel="name"
                        listClassName="list-container"
                    />
                </div>
                <div className="col-10 dictionaries-container">
                    {!selectedDictionary && (
                        <div className="text-center empty-container">
                            Выберите справочник для отображения
                        </div>
                    )}
                    {selectedDictionary?.code === 'worker' && (
                        <DictWorkerTable />
                    )}
                    {selectedDictionary?.code === 'workgroup' && (
                        <DictWorkgroupTable />
                    )}
                    {selectedDictionary?.code === 'radiography_equipment' && (
                        <RadiographyEquipTable />
                    )}
                    {selectedDictionary?.code === 'factory_index' && (
                        <DictFactoryIndexTable />
                    )}
                    {selectedDictionary?.code === 'factory_name' && (
                        <DictFactoryNameTable />
                    )}
                    {selectedDictionary?.code === 'element_type' && (
                        <DictElementTypeTable />
                    )}
                    {selectedDictionary?.code === 'standard_document' && (
                        <DictStandardDocumentTable />
                    )}
                    {selectedDictionary?.code === 'steel_grade' && (
                        <DictSteelGradeTable />
                    )}
                    {selectedDictionary?.code === 'weld-qa-responsible' && (
                        <DictWeldQaResponsibleTable />
                    )}
                    {selectedDictionary?.code === 'technical_sheet' && (
                        <DictTechnicalSheetTable />
                    )}
                </div>
            </div>
        </Dialog>
    );
};
