import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { WithRouter } from '../../containers/with-router';

const GasPipelineDashboard = lazy(
    () => import('./forms/gas-pipeline-dashboard')
);
const Sectors = lazy(() => import('./pages/sectors'));
const Weldbook = lazy(() => import('./pages/weldbook'));
const Passport = lazy(() => import('./pages/passport'));
const Editor = lazy(() => import('./pages/dcm-editor'));

export default (): JSX.Element => (
    <Routes>
        <Route
            key="gas-pipeline"
            path="/"
            element={
                <WithRouter>
                    <GasPipelineDashboard />
                </WithRouter>
            }
        />
        <Route
            key="sectors"
            path="/sectors"
            element={
                <WithRouter>
                    <Sectors />
                </WithRouter>
            }
        />
        <Route
            key="weldbook"
            path="/weldbook"
            element={
                <WithRouter>
                    <Weldbook />
                </WithRouter>
            }
        />
        <Route
            key="passport"
            path="/passport"
            element={
                <WithRouter>
                    <Passport />
                </WithRouter>
            }
        />
        <Route
            key="editor"
            path="/editor"
            element={
                <WithRouter>
                    <Editor />
                </WithRouter>
            }
        />
        <Route
            key="main/gas-pipeline"
            path="/*"
            element={<Navigate to="/gas-pipeline" replace />}
        />
    </Routes>
);
