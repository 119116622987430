import request from 'utils/http/request';
import {
    RadiographyContourCommentsBaseType,
    RadiographyContourCommentsResponseType,
} from './types';
import { IPagingSearch } from 'interfaces';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;
const url = getUrl(apiUrl);

export const getRadiographyContourCommentsListApi = async (): Promise<
    IPagingSearch<RadiographyContourCommentsResponseType>
> => {
    return request.get(url(''));
};

export const getRadiographyContourCommentsByIdApi = async (
    comment_id: string
): Promise<RadiographyContourCommentsResponseType> => {
    return request.get(url(comment_id));
};

export const updateRadiographyContourCommentsApi = async (
    uuid: string,
    body: RadiographyContourCommentsBaseType
): Promise<RadiographyContourCommentsResponseType> => {
    return request.patch(url(`${uuid}/`), body);
};

export const deleteRadiographyContourCommentsApi = async (
    uuid: string
): Promise<any> => {
    return request.delete(url(`${uuid}/`));
};
