import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getRadiographyContourCommentsListApi,
    getRadiographyContourCommentsByIdApi,
    updateRadiographyContourCommentsApi,
    deleteRadiographyContourCommentsApi,
} from './api';
import options from './options';
import {
    RadiographyContourCommentsBaseType,
    RadiographyContourCommentsResponseType,
} from './types';

const { name } = options;

export const getRadiographyContourCommentsList = createAsyncThunk(
    `${name}/getRadiographyContourCommentsList`,
    async (_, thunkApi) => {
        try {
            return await getRadiographyContourCommentsListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getRadiographyContourCommentsById = createAsyncThunk(
    `${name}/getRadiographyContourCommentsById`,
    async (comment_id: string, thunkApi) => {
        try {
            return await getRadiographyContourCommentsByIdApi(comment_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateRadiographyContourComments = createAsyncThunk(
    `${name}/updateRadiographyContourComments`,
    async (
        {
            uuid,
            body,
        }: { uuid: string; body: RadiographyContourCommentsBaseType },
        thunkApi
    ) => {
        try {
            return await updateRadiographyContourCommentsApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteRadiographyContourComments = createAsyncThunk(
    `${name}/deleteRadiographyContourComments`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteRadiographyContourCommentsApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
