import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUsersMeApi } from './api';
import options from './options';

const { name } = options;

export const getUsersMe = createAsyncThunk(
    `${name}/getUsersMe`,
    async (_, thunkApi) => {
        try {
            return await getUsersMeApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
