import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRadiographyContourCommentsListCumulative } from './actions';
import options from './options';

const { name } = options;

type allRadiographyCommentsCumulativeState = {
    isLoading: boolean;
    error: string;
    radiographyContourCommentsListCumulative: any[];
};

const initialState: allRadiographyCommentsCumulativeState = {
    isLoading: false,
    error: '',
    radiographyContourCommentsListCumulative: [],
};

export const radiographyContourCommentsListCumulativeSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearallRadiographyCommentsCumulative(state) {
            state.radiographyContourCommentsListCumulative = [];
        },
    },
    extraReducers: {
        [getRadiographyContourCommentsListCumulative.pending.type]: (state) => {
            state.isLoading = true;
        },
        [getRadiographyContourCommentsListCumulative.fulfilled.type]: (
            state,
            { payload }: PayloadAction<any>
        ) => {
            state.isLoading = false;
            state.error = '';
            state.radiographyContourCommentsListCumulative = payload.results;
        },
        [getRadiographyContourCommentsListCumulative.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export default radiographyContourCommentsListCumulativeSlice.reducer;
