import { AxiosError } from 'axios';
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from '../../models/auth/constants';

export const getResponseErrorMessage = (e: any) =>
    e.response?.data?.detail || e.message;

export const getTokenString = () =>
    localStorage.getItem(TOKEN_KEY)
        ? `Bearer ${localStorage.getItem(TOKEN_KEY)}`
        : '';

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);
