import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDomElementsForGroupApi } from './api';
import options from './options';

const { name } = options;

export const getDomElementsForGroup = createAsyncThunk(
    `${name}/getDomElementsForGroup`,
    async (group_name: string, thunkApi) => {
        try {
            return await getDomElementsForGroupApi(group_name);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
