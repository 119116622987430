import { Button } from 'primereact/button';
import React from 'react';

interface IDefectAnalysisToolbarProps {
    onExportCSV: () => void;
    isCSVFileLoading: boolean;
}

export const DefectAnalysisToolbar = ({
    onExportCSV,
    isCSVFileLoading,
}: IDefectAnalysisToolbarProps) => {
    return (
        <div className="flex justify-content-between align-items-center ml-2">
            <p className="block text-2xl font-bold">Анализ дефектов</p>
            <Button
                label="Экспортировать CSV"
                onClick={onExportCSV}
                disabled={isCSVFileLoading}
            />
        </div>
    );
};
