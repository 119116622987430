import request from 'utils/http/request';
import { getUrl } from 'models/utils';
import options from './options';

const { apiUrl } = options;

const url = getUrl(apiUrl);

export const getRadiographyGeneralCommentsListCumulativeApi =
    async (): Promise<any> => {
        return request.get(url(''));
    };
