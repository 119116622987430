import request from 'utils/http/request';
import {
    RadiographyGeneralCommentsBaseType,
    RadiographyGeneralCommentsResponseType,
} from './types';
import { IPagingSearch } from 'interfaces';
import { getUrl } from '../../utils';
import options from './options';

const { apiUrl } = options;
const url = getUrl(apiUrl);

export const getRadiographyGeneralCommentsListApi = async (): Promise<
    IPagingSearch<RadiographyGeneralCommentsResponseType>
> => {
    return request.get(url(''));
};

export const getRadiographyGeneralCommentsByIdApi = async (
    RadiographyGeneralComments_id: string
): Promise<RadiographyGeneralCommentsResponseType> => {
    return request.get(url(RadiographyGeneralComments_id));
};

export const createRadiographyGeneralCommentsApi = async (
    body: RadiographyGeneralCommentsBaseType
): Promise<RadiographyGeneralCommentsResponseType> => {
    return request.post(url(''), body);
};

export const updateRadiographyGeneralCommentsApi = async (
    uuid: string,
    body: RadiographyGeneralCommentsBaseType
): Promise<RadiographyGeneralCommentsResponseType> => {
    return request.patch(url(`${uuid}/`), body);
};

export const deleteRadiographyGeneralCommentsApi = async (
    uuid: string
): Promise<any> => {
    return request.delete(url(`${uuid}/`));
};
