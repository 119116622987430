import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
    Box,
    Button,
    Divider,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { NavigateFunction } from 'react-router';

interface IVtdErrorBoundaryProps {
    navigate: NavigateFunction;
    reloadButton?: boolean;
    children?: React.ReactElement | React.ReactElement[];
}

const styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 300,
    },
    buttonBox: {
        marginTop: 16,
    },
    button: {
        margin: 16,
    },
});

const useStyle = makeStyles(styles);

export default function VtdErrorBoundary({
    navigate,
    reloadButton = true,
    children,
}: IVtdErrorBoundaryProps) {
    const classes = useStyle();

    const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
        const reloadHandler = () => {
            navigate(0);
            resetErrorBoundary();
        };

        return (
            <Box className={classes.container}>
                <Typography>Произошла ошибка приложения:</Typography>
                <pre>{error.message}</pre>
                <Divider style={{ width: '100%' }} />
                <Box className={classes.buttonBox}>
                    {reloadButton && (
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            onClick={reloadHandler}
                        >
                            Перезагрузить страницу
                        </Button>
                    )}
                </Box>
            </Box>
        );
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {children}
        </ErrorBoundary>
    );
}
