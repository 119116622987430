import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getRadiographyGeneralCommentsListApi,
    getRadiographyGeneralCommentsByIdApi,
    updateRadiographyGeneralCommentsApi,
    deleteRadiographyGeneralCommentsApi,
} from './api';
import options from './options';
import { RadiographyGeneralCommentsBaseType } from './types';

const { name } = options;

export const getRadiographyGeneralCommentsList = createAsyncThunk(
    `${name}/getRadiographyGeneralCommentsList`,
    async (_, thunkApi) => {
        try {
            return await getRadiographyGeneralCommentsListApi();
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const getRadiographyGeneralCommentsById = createAsyncThunk(
    `${name}/getRadiographyGeneralCommentsById`,
    async (pipe_id: string, thunkApi) => {
        try {
            return await getRadiographyGeneralCommentsByIdApi(pipe_id);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateRadiographyGeneralComments = createAsyncThunk(
    `${name}/updateRadiographyGeneralComments`,
    async (
        {
            uuid,
            body,
        }: { uuid: string; body: RadiographyGeneralCommentsBaseType },
        thunkApi
    ) => {
        try {
            return await updateRadiographyGeneralCommentsApi(uuid, body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteRadiographyGeneralComments = createAsyncThunk(
    `${name}/deleteRadiographyGeneralComments`,
    async (uuid: string, thunkApi) => {
        try {
            return await deleteRadiographyGeneralCommentsApi(uuid);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
