import { Column, ColumnProps, ColumnSortEvent } from 'primereact/column';
import { DataTable, DataTableSelectEvent } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import React, { useEffect, useState } from 'react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { convertDate } from 'utils/date';
import { convertFileType } from 'pages/gas-pipeline/pages/passport/forms/helpers';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ICommentFilter } from '../helpers';

interface IDefectAnalysisTableProps {
    data: any;
    resonsFilter: ICommentFilter[];
    commentTypesFilter: ICommentFilter[];
    markedState: [
        { name: string; value: boolean },
        { name: string; value: boolean }
    ];
    downloadProgressValue: number;
    onChangeReasonsFilter: (data: ICommentFilter[]) => void;
    onChangeCommentTypesFilter: (data: ICommentFilter[]) => void;
    onCheckMarked: (index: number, value: boolean) => void;
    onSetCommentMarked: (
        event: CheckboxChangeEvent,
        data: any,
        options: any
    ) => void;
    onDownloadDcmFile: (event: any, data: any) => void;
    onDeleteComment: (data: any) => void;
    onSetDateTimeordering: (data: any) => void;
}

export const DefectAnalysisTable = ({
    data,
    resonsFilter,
    commentTypesFilter,
    markedState,
    downloadProgressValue,
    onChangeReasonsFilter,
    onChangeCommentTypesFilter,
    onCheckMarked,
    onSetCommentMarked,
    onDownloadDcmFile,
    onDeleteComment,
    onSetDateTimeordering,
}: IDefectAnalysisTableProps) => {
    /* стейт для корректного рендера прогрессбара для загрузки файла радиографии */
    const [selectedComment, setSelectedComment] = useState<any>(null);

    const [deletingComment, setDeletingComment] = useState<any>(null);

    const reasonTemplate = (data: any, options: any): any => {
        if (data.radiography_contour_comments) {
            return '-';
        } else {
            const reasons: string[] = [];
            if (data.radiography_general_comments?.detect_qa) {
                reasons.push('Неудовлетворительное качество снимка;');
            }
            if (data.radiography_general_comments?.detect_seam) {
                reasons.push('Неправильно выполнено распознавание шва;');
            }
            if (data.radiography_general_comments?.detect_defects) {
                reasons.push('Неправильно выполнено распознавание дефектов;');
            }
            if (data.radiography_general_comments?.detect_beltmarks) {
                reasons.push(
                    'Неправильно выполнено распознавание меток мерного пояса;'
                );
            }
            if (data.radiography_general_comments?.calculate_defect_offset) {
                reasons.push(
                    'Неправильно выполнено вычисление смещения дефектов;'
                );
            }
            if (data.radiography_general_comments?.conclusion) {
                reasons.push('Неверное заключение по КСС;');
            }
            return (
                <div>
                    {reasons.length === 0 && <div>-</div>}
                    {reasons.map((reason: string) => (
                        <div key={reason}>{reason}</div>
                    ))}
                </div>
            );
        }
    };

    /* Обработчик для изменения фильтра по причинам отбраковки */
    const handleChangeReasonsFilter = (event: any) => {
        const updatedReasonsFilter = resonsFilter.map(
            (reason: ICommentFilter) => {
                if (reason.name === event.value.name) {
                    reason.value = event.checked;
                }
                return reason;
            }
        );
        onChangeReasonsFilter(updatedReasonsFilter);
    };

    /* Обработчик для изменения фильтра по типу комментариев */
    const handleChangeCommentTypesFilter = (event: any) => {
        const updatedCommentTypesFilter = commentTypesFilter.map(
            (commentType: ICommentFilter) => {
                if (commentType.name === event.value.name) {
                    commentType.value = event.checked;
                }
                return commentType;
            }
        );
        onChangeCommentTypesFilter(updatedCommentTypesFilter);
    };

    /* Обработчик для открытия фала радиографии при клике на строку в таблице */
    const handleRowClick = (event: DataTableSelectEvent) => {
        event.originalEvent.stopPropagation();
        const uuid = event.data.radiography.item_uuid;
        if (uuid) {
            window.open(
                window.location.origin +
                    `/gas-pipeline/editor?radiography_id=${uuid}`,
                '_blank'
            );
        }
    };

    /* Обработчик для сортировки строк в таблице по дате создания комментария */
    const sortItemsByDate = (event: ColumnSortEvent) => {
        onSetDateTimeordering(
            event.order === -1
                ? '-row_created_datetime'
                : 'row_created_datetime'
        );
        return event.data.sort((a: any, b: any) => {
            // Парсинг дат в timestamp
            const timeA = a.radiography_general_comments
                ? new Date(
                      a.radiography_general_comments?.row_created_datetime
                  ).getTime()
                : new Date(
                      a.radiography_contour_comments?.row_created_datetime
                  ).getTime();
            const timeB = b.radiography_general_comments
                ? new Date(
                      b.radiography_general_comments?.row_created_datetime
                  ).getTime()
                : new Date(
                      b.radiography_contour_comments?.row_created_datetime
                  ).getTime();

            // Сортировка по направлению
            return event.order === 1 ? timeA - timeB : timeB - timeA;
        });
    };

    const footerContent = (
        <div className="flex w-full justify-content-end">
            <Button
                severity="danger"
                text
                label="Удалить"
                className="mr-3"
                icon="pi pi-trash"
                size="small"
                onClick={async () => {
                    await onDeleteComment(deletingComment!);
                    setDeletingComment(null);
                }}
            />
            <Button
                label="Оставить"
                onClick={() => setDeletingComment(null)}
                size="small"
            />
        </div>
    );

    useEffect(() => {
        if (downloadProgressValue === 0) {
            setSelectedComment(null);
        }
    }, [downloadProgressValue]);

    return (
        <div className="defectAnalysisContainer">
            <div className="mt-3">
                <Tag
                    value="Просмотрено"
                    className={`${
                        markedState[0].value ? 'markTag_checked' : 'markTag'
                    } cursor-pointer mr-2`}
                    icon={markedState[0].value ? 'pi pi-check' : 'pi pi-circle'}
                    onClick={() => onCheckMarked(0, !markedState[0].value)}
                />
                <Tag
                    value="Не просмотрено"
                    className={`${
                        markedState[1].value ? 'markTag_checked' : 'markTag'
                    } cursor-pointer`}
                    icon={markedState[1].value ? 'pi pi-check' : 'pi pi-circle'}
                    onClick={() => onCheckMarked(1, !markedState[1].value)}
                />
            </div>
            <div className="w-full">
                <DataTable
                    value={data}
                    emptyMessage="Нет данных для отображения"
                    className="mt-2"
                    scrollable
                    paginator
                    rows={20}
                    rowsPerPageOptions={[20, 50, 100, 200]}
                    selectionMode="single"
                    rowHover
                    onRowSelect={handleRowClick}
                    sortField="row_created_datetime"
                    sortOrder={-1}
                >
                    <Column
                        field="mark"
                        frozen
                        header=""
                        style={{ width: '55px' }}
                        body={(data, options) => (
                            <Checkbox
                                checked={
                                    data.radiography_general_comments
                                        ? data.radiography_general_comments
                                              ?.mark
                                        : data.radiography_contour_comments
                                              ?.mark
                                }
                                tooltip={
                                    data.radiography_general_comments
                                        ? data.radiography_general_comments
                                              ?.mark
                                            ? 'Отметить комментарий как не просмотренный'
                                            : 'Отметить комментарий как просмотренный'
                                        : data.radiography_contour_comments
                                              ?.mark
                                        ? 'Отметить комментарий как не просмотренный'
                                        : 'Отметить комментарий как просмотренный'
                                }
                                onChange={(event: CheckboxChangeEvent) =>
                                    onSetCommentMarked(event, data, options)
                                }
                            />
                        )}
                    />
                    <Column
                        field="number"
                        header="№"
                        style={{ minWidth: '50px' }}
                        body={(data, columnProps) => columnProps.rowIndex + 1}
                    />
                    <Column
                        field=""
                        header="Причина отбраковки"
                        filter
                        showFilterMatchModes={false}
                        showFilterMenuOptions={false}
                        showApplyButton={false}
                        showClearButton={false}
                        body={reasonTemplate}
                        filterMenuClassName="stage-filter"
                        style={{ width: '442px' }}
                        className={
                            resonsFilter.some((r) => r.value)
                                ? 'activeFilter'
                                : ''
                        }
                        filterElement={(options) => (
                            <>
                                {resonsFilter.map((item: ICommentFilter) => (
                                    <div
                                        key={item.field}
                                        className="flex align-items-center mt-2"
                                    >
                                        <Checkbox
                                            inputId={item.name}
                                            name="name"
                                            value={item}
                                            checked={item.value}
                                            onChange={(
                                                e: CheckboxChangeEvent
                                            ) => handleChangeReasonsFilter(e)}
                                        />
                                        <label
                                            htmlFor={item.field}
                                            className="ml-2"
                                        >
                                            {item.name}
                                        </label>
                                    </div>
                                ))}
                            </>
                        )}
                    />
                    <Column
                        field="comment"
                        header="Комментарий"
                        style={{ width: '360px' }}
                        body={(data) =>
                            data.radiography_general_comments
                                ? data.radiography_general_comments?.comment
                                : data.radiography_contour_comments?.comment
                        }
                    />
                    <Column
                        field=""
                        header="Тип комментария"
                        filter
                        showFilterMatchModes={false}
                        showFilterMenuOptions={false}
                        showApplyButton={false}
                        showClearButton={false}
                        filterMenuClassName="stage-filter"
                        className={
                            commentTypesFilter.some((r) => r.value)
                                ? 'activeFilter'
                                : ''
                        }
                        filterElement={(options) => (
                            <>
                                {commentTypesFilter.map(
                                    (item: ICommentFilter) => (
                                        <div
                                            key={item.field}
                                            className="flex align-items-center mt-2"
                                        >
                                            <Checkbox
                                                inputId={item.name}
                                                name="name"
                                                value={item}
                                                checked={item.value}
                                                onChange={(
                                                    e: CheckboxChangeEvent
                                                ) =>
                                                    handleChangeCommentTypesFilter(
                                                        e
                                                    )
                                                }
                                            />
                                            <label
                                                htmlFor={item.field}
                                                className="ml-2"
                                            >
                                                {item.name}
                                            </label>
                                        </div>
                                    )
                                )}
                            </>
                        )}
                        body={(data) => (
                            <Tag
                                value={
                                    data.radiography_contour_comments
                                        ? 'к объекту'
                                        : 'к снимку'
                                }
                                style={{
                                    backgroundColor: '#E9ECEF',
                                    color: '#4C555C',
                                }}
                            />
                        )}
                    />
                    <Column
                        field=""
                        header="Автор"
                        body={(data) =>
                            `${data.welduser_welduser?.last_name ?? ''} ${
                                data.welduser_welduser?.first_name ?? ''
                            }`
                        }
                    />
                    <Column
                        field="row_created_datetime"
                        header="Дата и время"
                        sortable
                        sortFunction={(event: ColumnSortEvent) =>
                            sortItemsByDate(event)
                        }
                        body={(data) => {
                            const date = data.radiography_general_comments
                                ? data.radiography_general_comments
                                      ?.row_created_datetime
                                : data.radiography_contour_comments
                                      ?.row_created_datetime;
                            return convertDate(date);
                        }}
                    />
                    <Column
                        field="element.element_1_diameter"
                        header="Диаметр трубы, мм"
                        style={{ width: '125px' }}
                    />
                    <Column
                        field="element.element_1_side_width"
                        header="Толщина стенки трубы (S1), мм"
                        style={{ width: '132px' }}
                    />
                    <Column
                        field="element.element_2_side_width"
                        header="Толщина стенки трубы (S2), мм"
                        style={{ width: '132px' }}
                    />
                    <Column
                        field="radiography.original_file_type"
                        header="Тип детектора"
                        body={(data) =>
                            convertFileType(
                                data.radiography?.original_file_type
                            )
                        }
                    />
                    <Column
                        field="radiography.original_file_name"
                        header="Название снимка"
                    />
                    <Column field="weldbook.number_by_user" header="КСС" />
                    <Column field="sector.name" header="Участок" />
                    <Column field="object.name" header="Объект" />
                    <Column
                        field=""
                        header=""
                        style={{ width: '100px' }}
                        body={(data, options) => {
                            const dataId = data.radiography_general_comments
                                ? data.radiography_general_comments?.item_uuid
                                : data.radiography_contour_comments?.item_uuid;
                            return (
                                <div className="flex w-full">
                                    <div
                                        className={`tooltip-upload-${options.rowIndex} mr-3`}
                                        data-pr-tooltip="Загрузить снимок"
                                        data-pr-position="bottom"
                                        data-pr-disabled={
                                            downloadProgressValue !== 0 &&
                                            selectedComment === dataId
                                        }
                                        style={{ width: '16px' }}
                                    >
                                        {selectedComment !== dataId && (
                                            <i
                                                className={`block pi pi-upload cursor-pointer  `}
                                                onClick={(event: any) => {
                                                    const SelectedDataId =
                                                        data.radiography_general_comments
                                                            ? data
                                                                  .radiography_general_comments
                                                                  .item_uuid
                                                            : data
                                                                  .radiography_contour_comments
                                                                  .item_uuid;
                                                    onDownloadDcmFile(
                                                        event,
                                                        data.radiography
                                                    );
                                                    setSelectedComment(
                                                        SelectedDataId
                                                    );
                                                }}
                                            />
                                        )}
                                        {selectedComment === dataId &&
                                            downloadProgressValue < 100 &&
                                            downloadProgressValue > 0 && (
                                                <i className="block pi pi-spin pi-spinner " />
                                            )}
                                    </div>

                                    <i
                                        className={`block pi pi-trash cursor-pointer  tooltip-delete-${options.rowIndex}`}
                                        style={{
                                            right: '15px',
                                        }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setDeletingComment(data);
                                        }}
                                        data-pr-tooltip="Удалить"
                                        data-pr-position="bottom"
                                    />
                                    <Tooltip
                                        target={`.tooltip-upload-${options.rowIndex}`}
                                    />
                                    <Tooltip
                                        target={`.tooltip-delete-${options.rowIndex}`}
                                    />
                                </div>
                            );
                        }}
                    />
                </DataTable>
                {!!deletingComment && (
                    <Dialog
                        header="Удаление комментария"
                        visible={!!deletingComment}
                        style={{ width: '25vw' }}
                        onHide={() => setDeletingComment(null)}
                        footer={footerContent}
                    >
                        <div className="text-sm font-normal mt-1">
                            Вы уверены, что хотите удалить комментарий ?
                        </div>
                    </Dialog>
                )}
            </div>
        </div>
    );
};
