import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllRadiographyCommentsCumulativeApi } from './api';
import options from './options';

const { name } = options;

export const getAllRadiographyCommentsCumulative = createAsyncThunk(
    `${name}/getAllRadiographyCommentsCumulative`,
    async (radiography_uuid: string, thunkApi) => {
        try {
            return await getAllRadiographyCommentsCumulativeApi(
                radiography_uuid
            );
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
