import React, { ReactElement, useEffect, useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import {
    DictFactoryIndexBaseType,
    DictFactoryIndexResponseType,
} from 'models/dictionaries/dict-factory-index/types';
import { selectDictFactoryIndexState } from 'models/dictionaries/dict-factory-index/selectors';
import { useAppSelector } from 'store/hooks';

interface IFactoryIndexDialogProps {
    formData: any;
    onClose: () => void;
    onCreate?: (item: DictFactoryIndexBaseType) => void;
    onUpdate?: (item_uuid: string, item: DictFactoryIndexResponseType) => void;
}

const dictFactoryIndexInitialValues: DictFactoryIndexBaseType = {
    value: '',
};

export const FactoryIndexDialog = ({
    formData,
    onClose,
    onCreate,
    onUpdate,
}: IFactoryIndexDialogProps): ReactElement => {
    const [visible, setVisible] = useState<boolean>(true);
    const { dictFactoryIndexList, isLoading } = useAppSelector(
        selectDictFactoryIndexState
    );

    const formik = useFormik({
        initialValues: formData || dictFactoryIndexInitialValues,
        validateOnBlur: true,
        validate: (data) => {
            const errors: any = {};

            if (!data.value || data.value?.trim() === '') {
                errors.value = 'Обязательное поле ввода';
            }
            if (data.value.length > 255) {
                errors.value =
                    'Вы достигли лимита символов. Максимальное количество: 255';
            }

            const filtereredFactoryIndexList = formData
                ? dictFactoryIndexList.filter(
                      (f: DictFactoryIndexResponseType) =>
                          f.item_uuid !== data.item_uuid
                  )
                : dictFactoryIndexList;

            if (
                data.value?.trim() &&
                filtereredFactoryIndexList.some(
                    (f: DictFactoryIndexResponseType) =>
                        f.value === data.value.trim()
                )
            ) {
                errors.value = 'Такой заводской номер элемента уже существует';
            }
            return errors;
        },
        onSubmit: (values: any) => {
            if (formData) {
                onUpdate && onUpdate(formData.item_uuid, values);
            } else {
                onCreate && onCreate(values);
            }
        },
    });

    const isFormInvalid = () => {
        if (
            formik.values.value.trim() &&
            formik.values.value.length <= 255 &&
            !formik.errors.value
        ) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
            <Dialog
                header={`${
                    formData ? 'Редактирование' : 'Добавление'
                } заводского номера элемента`}
                visible={visible}
                style={{ width: '40vw' }}
                onHide={onClose}
            >
                <div className="grid align-items-end">
                    <div className="col-12">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="second_name">
                                Заводской номер элемента{' '}
                                <span className="required">*</span>
                            </label>
                            <InputText
                                id="value"
                                name="value"
                                value={formik.values.value}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'value',
                                        e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                required
                                className={classNames({
                                    'p-invalid': formik.errors.value,
                                })}
                                tooltip={formik.errors.value as string}
                                tooltipOptions={{
                                    disabled: !formik.errors.value,
                                    position: 'bottom',
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex w-full justify-content-end mt-3">
                    <Button
                        outlined
                        onClick={onClose}
                        label="Отмена"
                        className="mr-3"
                    />
                    {!formData && (
                        <Button
                            type="submit"
                            label="Добавить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                    {formData && (
                        <Button
                            type="submit"
                            label="Сохранить"
                            disabled={isFormInvalid()}
                            onClick={(e) => formik.handleSubmit()}
                        />
                    )}
                </div>
            </Dialog>
        </form>
    );
};
