import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import options from './options';
import { exportCSV } from './actions';

const { name } = options;

type ExportCSVState = {
    isLoading: boolean;
    exportCSV: any;
    error: string;
};

const initialState: ExportCSVState = {
    isLoading: false,
    error: '',
    exportCSV: null,
};

export const exportCSVSlice = createSlice({
    name,
    initialState,
    reducers: {
        clearexportCSV(state) {
            state.exportCSV = null;
        },
    },
    extraReducers: {
        [exportCSV.pending.type]: (state) => {
            state.isLoading = true;
        },
        [exportCSV.fulfilled.type]: (
            state,
            { payload }: PayloadAction<Blob>
        ) => {
            state.isLoading = false;
            state.error = '';
            state.exportCSV = payload;
        },
        [exportCSV.rejected.type]: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
    },
});

export default exportCSVSlice.reducer;
