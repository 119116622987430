import { createAsyncThunk } from '@reduxjs/toolkit';
import options from './options';
import { exportCSVApi } from './api';
import { exportCSVType } from './types';

const { name } = options;

export const exportCSV = createAsyncThunk(
    `${name}/exportCSV`,
    async (body: exportCSVType, thunkApi) => {
        try {
            return await exportCSVApi(body);
        } catch (e: any) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);
